import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/react';

import './clientConfig'; // import as early as possible to run required config setup
import './index.scss';
import './polyfills';
import App from './App';
import { initWebAPIConfig } from './apiClients/apiUtil';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import theme from './theme';
import { appConfig } from '../commonConfig';

Sentry.init({
  dsn: appConfig.sentryBrowserDsn,
  environment: appConfig.targetEnv,
});

initWebAPIConfig();

const Main = () => {
  // Remove material ui styles injected during server rendering
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <EmotionThemeProvider theme={theme}>
            <App />
          </EmotionThemeProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

loadableReady(() => {
  const root = document.getElementById('root');
  ReactDOM.hydrate(<Main />, root);
});
