import React, { useContext } from 'react';
import classNames from 'classnames';

import GooseChaseIconDark from '../../media/goosechase-icon-dark.svg';
import RemoteIcon from '../../media/richIcons/remote.svg';
import OnboardingIcon from '../../media/richIcons/onboarding.svg';
import TourismIcon from '../../media/richIcons/tourism.svg';
import K12Icon from '../../media/richIcons/k12.svg';
import RecreationIcon from '../../media/richIcons/recreation.svg';
import CampusOrientationIcon from '../../media/richIcons/campusOrientation.svg';

import styles from './Navbar.module.scss';
import Container from '../layout/Container';
import Link from '../Link';
import routes from '../../routes';
import { UserDataContext } from '../../App';
import { getFullUserIconUrl } from '../../services/userService';
import Image from '../Image';
import appConstants from '../../appConstants';
import SkeletonLoader from '../SkeletonLoader';
import NavDropdown from './NavDropdown';
import { NavMenuItem } from './NavMenuItem';
import Icon from '../icons/Icon';
import Flex from '../layout/Flex';
import NavHamburger from './NavHamburger';
import { appConfig } from '../../../commonConfig';
import { useIsEduPage } from '../../hooks/utilHooks';
import { useMediaQuery } from '@material-ui/core';
import { ResourcesNav } from './ResourcesNav';

type Props = {
  mobileMenuOpen: boolean;
  onMobileMenuToggle(): void;
};

const Navbar = (props: Props) => {
  const { userData } = useContext(UserDataContext);
  const isEduPage = useIsEduPage();

  // js media query is used to not render the same links in the DOM twice (for SEO)
  const isDesktop = useMediaQuery(`(min-width: ${styles.mobileNavBreakpointStr})`);

  const renderUserSection = (mobileNav = false) => {
    if (appConstants.HAS_DJANGO_SESSION_COOKIE && (!userData.reqStatus || userData.reqStatus === 'pending')) {
      if (mobileNav) {
        return null;
      }

      return (
        <Flex alignItems="center" className={styles.userSectionLinkItem}>
          <SkeletonLoader variant="avatar" className={styles.userIconContainer} />
          <SkeletonLoader style={{ width: '8rem' }} />
        </Flex>
      );
    }

    const linkItemClass = mobileNav ? styles.mobileNavLink : styles.linkItem;

    if (!userData.user) {
      return (
        <>
          {mobileNav && <Divider />}
          <Link variant="dark" className={linkItemClass} href={routes.LOGIN.path} useRouter>
            Log in
          </Link>
          {mobileNav && <Divider />}
          <Link
            variant="btnSmOutline"
            fullWidth={mobileNav}
            className={mobileNav ? styles.mobileCreateGameLink : undefined}
            href={isEduPage ? routes.EDU_SIGN_UP.path : routes.SIGN_UP.path}
            useRouter
          >
            Get started
          </Link>
        </>
      );
    }

    const userMenuItems = (
      <>
        <Link variant="dark" fullWidth href={`${appConfig.webAppBaseUrl}/account-info`} className={styles.userMenuItem}>
          Account Info
        </Link>
        <Link
          variant="dark"
          fullWidth
          href={`${appConfig.webAppBaseUrl}/change-password`}
          className={styles.userMenuItem}
        >
          Change Password
        </Link>
        {userData.user.num_pending_invites > 0 && (
          <Link
            variant="dark"
            fullWidth
            href={`${appConfig.webAppBaseUrl}/pending-invites`}
            className={styles.userMenuItem}
          >
            Pending Invites <span className={styles.numberBadge}>{userData.user.num_pending_invites}</span>
          </Link>
        )}
        {userData.user.num_pending_requests > 0 && (
          <Link
            variant="dark"
            fullWidth
            href={`${appConfig.webAppBaseUrl}/pending-requests`}
            className={styles.userMenuItem}
          >
            Pending Requests <span className={styles.numberBadge}>{userData.user.num_pending_requests}</span>
          </Link>
        )}
        {userData.user.belongs_to_organization && (
          <>
            <Divider noMargin />
            <Link
              variant="dark"
              fullWidth
              href={`${appConfig.webAppBaseUrl}/organization-info`}
              className={styles.userMenuItem}
            >
              Organization Info
              {!!userData.user.num_pending_org_member_requests && (
                <span className={styles.numberBadge}>{userData.user.num_pending_org_member_requests}</span>
              )}
            </Link>
          </>
        )}
        <Divider noMargin />
        <Link variant="dark" fullWidth href={`${appConfig.webAppBaseUrl}/logout`} className={styles.userMenuItem}>
          Logout
        </Link>
      </>
    );

    const userImgAndName = (
      <>
        {mobileNav ? (
          <Flex className={styles.mobileUserNameContainer} alignItems="center">
            <div className={styles.userIconContainer}>
              <Image
                className={styles.userIcon}
                src={getFullUserIconUrl(userData.user.display_picture)}
                width="32"
                height="32"
                alt="user icon"
              />
            </div>
            <div>{userData.user.username}</div>
          </Flex>
        ) : (
          <NavDropdown className={styles.userSectionLinkItem} menuPlacement="bottom-end">
            <NavDropdown.Toggle>
              <Flex alignItems="center">
                <div className={styles.userIconContainer}>
                  <Image
                    className={styles.userIcon}
                    src={getFullUserIconUrl(userData.user.display_picture)}
                    width="32"
                    height="32"
                    alt="user icon"
                  />
                </div>
                <div className={styles.username}>{userData.user.username}</div>
                <Icon name="arrowDown" height="2.4rem" css={{ verticalAlign: 'bottom' }} />
              </Flex>
            </NavDropdown.Toggle>
            <NavDropdown.Menu>{userMenuItems}</NavDropdown.Menu>
          </NavDropdown>
        )}
      </>
    );

    return (
      <>
        {mobileNav && <Divider />}
        <Link variant="dark" className={linkItemClass} href={`${appConfig.webAppBaseUrl}/games`}>
          My Experiences
        </Link>
        {mobileNav ? (
          <>
            <Divider />
            {userImgAndName}
            <div className={styles.mobileUserItems}>{userMenuItems}</div>
          </>
        ) : (
          userImgAndName
        )}
      </>
    );
  };

  function renderNavMenuItem(
    name:
      | 'virtual'
      | 'onboarding'
      | 'k12'
      | 'recreation'
      | 'campus'
      | 'organizing'
      | 'playing'
      | 'whatIsIxp'
      | 'sxlPricing'
      | 'subscriptions'
      | 'k12Pricing'
      | 'tourism'
  ) {
    switch (name) {
      case 'virtual':
        return (
          <NavMenuItem
            icon={RemoteIcon}
            title="Team building"
            desc="Bring your virtual, hybrid or in-person team together."
            href={routes.VIRTUAL_TEAMS_USES.path}
          />
        );
      case 'onboarding':
        return (
          <NavMenuItem
            icon={OnboardingIcon}
            title="Onboarding"
            desc="Create an experience that gets new hires prepped and pumped."
            href={routes.ONBOARDING_USES.path}
          />
        );
      case 'k12':
        return (
          <NavMenuItem
            icon={K12Icon}
            title="K-12 education"
            desc="Boost learning by making the curriculum fun and interactive."
            href={routes.K12_EDUCATION_USES.path}
          />
        );
      case 'recreation':
        return (
          <NavMenuItem
            icon={RecreationIcon}
            title="Recreation"
            desc="Activate your community to have fun and do good together!"
            href={routes.RECREATION_USES.path}
          />
        );
      case 'campus':
        return (
          <NavMenuItem
            icon={CampusOrientationIcon}
            title="Campus orientation"
            desc="Plan events that bring your campus to life"
            href={routes.CAMPUS_ORIENTATION_USES.path}
          />
        );
      case 'organizing':
        return (
          <NavMenuItem
            title="Organizing"
            desc="Learn how to create your first Experience."
            href={routes.HIW_ORGANIZING.path}
          />
        );
      case 'playing':
        return (
          <NavMenuItem title="Playing" desc="Ready to play? Learn how to get started." href={routes.HIW_PLAYING.path} />
        );
      case 'whatIsIxp':
        return (
          <NavMenuItem
            title="What is an IXP?"
            desc="Find out what an interactive experience platform can do."
            href={routes.WHAT_IS_AN_IXP.path}
          />
        );
      case 'sxlPricing':
        return (
          <NavMenuItem
            title="Single Experience"
            desc="One-off events for communities of all sizes"
            href={routes.PRICING.path}
          />
        );
      case 'subscriptions':
        return (
          <NavMenuItem
            title="Subscriptions"
            desc="Unlimited access for your whole organization"
            href={routes.WHY_BUY_A_SUBSCRIPTION.path}
          />
        );
      case 'k12Pricing':
        return (
          <NavMenuItem
            title="K-12 Educator"
            desc="For K-12 teachers, schools & districts"
            href={routes.EDU_PRICING.path}
          />
        );
      case 'tourism':
        return (
          <NavMenuItem
            icon={TourismIcon}
            title="Tourism"
            desc="Customize interactive experiences that highlight the best of your attraction."
            href={routes.TOURISM_USES.path}
          />
        );
      default:
        return null;
    }
  }

  return (
    <nav className={styles.container}>
      <Container maxWidth="lg">
        <div className={styles.navbar}>
          <div className={styles.mainLinkGroup}>
            <Link className={styles.logoLink} href={routes.HOME.path} useRouter aria-label="Goosechase home">
              <GooseChaseIconDark className={styles.logo} />
            </Link>
            {isDesktop && (
              <div className={styles.horizLinkGroup}>
                <NavDropdown className={styles.linkItem}>
                  <NavDropdown.Toggle>
                    Uses <Icon name="arrowDown" height="2.4rem" css={{ verticalAlign: 'bottom' }} />
                  </NavDropdown.Toggle>
                  <NavDropdown.Menu>
                    <Flex>
                      <div className={styles.navUsesColumn}>
                        {renderNavMenuItem('virtual')}
                        {renderNavMenuItem('onboarding')}
                        {renderNavMenuItem('k12')}
                      </div>
                      <div className={styles.navUsesColumn}>
                        {renderNavMenuItem('recreation')}
                        {renderNavMenuItem('campus')}
                        {renderNavMenuItem('tourism')}
                      </div>
                    </Flex>
                  </NavDropdown.Menu>
                </NavDropdown>
                <NavDropdown className={styles.linkItem}>
                  <NavDropdown.Toggle>
                    How it works <Icon name="arrowDown" height="2.4rem" css={{ verticalAlign: 'bottom' }} />
                  </NavDropdown.Toggle>
                  <NavDropdown.Menu>
                    <div className={styles.singleColumnMenuContent}>
                      {renderNavMenuItem('whatIsIxp')}
                      {renderNavMenuItem('organizing')}
                      {renderNavMenuItem('playing')}
                    </div>
                  </NavDropdown.Menu>
                </NavDropdown>
                <NavDropdown className={styles.linkItem}>
                  <NavDropdown.Toggle>
                    Pricing <Icon name="arrowDown" height="2.4rem" css={{ verticalAlign: 'bottom' }} />
                  </NavDropdown.Toggle>
                  <NavDropdown.Menu>
                    <div className={styles.singleColumnMenuContent}>
                      {renderNavMenuItem('subscriptions')}
                      {renderNavMenuItem('sxlPricing')}
                      {renderNavMenuItem('k12Pricing')}
                    </div>
                  </NavDropdown.Menu>
                </NavDropdown>
                <Link variant="dark" className={styles.linkItem} href={routes.ABOUT.path} useRouter>
                  About us
                </Link>
                <ResourcesNav isDesktop={isDesktop} />
              </div>
            )}
          </div>
          {isDesktop && <div className={styles.userLinkGroup}>{renderUserSection()}</div>}
          <NavHamburger mobileMenuOpen={props.mobileMenuOpen} onMobileMenuToggle={props.onMobileMenuToggle} />
        </div>
      </Container>

      {!isDesktop && (
        <div className={classNames({ [styles.hidden]: !props.mobileMenuOpen })}>
          <Container maxWidth="lg" className={styles.mobileMenu}>
            <div className={styles.mobileGroupTitle}>Uses</div>
            {renderNavMenuItem('virtual')}
            {renderNavMenuItem('onboarding')}
            {renderNavMenuItem('k12')}
            {renderNavMenuItem('recreation')}
            {renderNavMenuItem('campus')}
            {renderNavMenuItem('tourism')}

            <Divider />

            <div className={styles.mobileGroupTitle}>How it works</div>
            {renderNavMenuItem('whatIsIxp')}
            {renderNavMenuItem('organizing')}
            {renderNavMenuItem('playing')}

            <Divider />

            <div className={styles.mobileGroupTitle}>Pricing</div>
            {renderNavMenuItem('subscriptions')}
            {renderNavMenuItem('sxlPricing')}
            {renderNavMenuItem('k12Pricing')}

            <Divider />
            <Link variant="dark" className={styles.mobileNavLink} href={routes.ABOUT.path} useRouter>
              About us
            </Link>
            <Divider />
            <ResourcesNav isDesktop={isDesktop} />
            {renderUserSection(true)}
          </Container>
        </div>
      )}
    </nav>
  );
};

const Divider = ({ noMargin }: { noMargin?: boolean }) => (
  <hr className={classNames({ [styles.divider]: !noMargin, [styles.dividerNoMargin]: noMargin })} />
);

export default Navbar;
