import { getAbsoluteUrlMinusQuery } from '../util';
import { parseErr, parseRes, APIResponse, webAPI } from './apiUtil';

type ContactUsReq = {
  name: string;
  email: string;
  message: string;
  sourceSite?: string;
  mixpanelDistinctId?: string;
  intercomDistinctId?: string;
  segmentDistinctId?: string;
};
type ContactUsRes = {};

type RequestQuoteReq = {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  interestSelect: string;
  participantCount: string;
  message: string;
  sentFromPage: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  mixpanelDistinctId?: string;
  intercomDistinctId?: string;
  segmentDistinctId?: string;
  eventLabel?: string;
  page?: string;
};
type RequestQuoteRes = {};

type SubscribeToNewsletterReq = {
  email: string;
};
type SubscribeToNewsletterRes = {};

export function contactUs(data: ContactUsReq): APIResponse<ContactUsRes> {
  return webAPI.post('/misc/contact-us/', data).then(parseRes).catch(parseErr);
}

export function requestQuote(data: RequestQuoteReq): APIResponse<RequestQuoteRes> {
  return webAPI.post('/misc/request-quote/', data).then(parseRes).catch(parseErr);
}

export function subscribeToNewsletter(data: SubscribeToNewsletterReq): APIResponse<SubscribeToNewsletterRes> {
  return webAPI
    .post('/misc/marketing-email-subscription/', {
      ...data,
      pageAbsoluteUrl: getAbsoluteUrlMinusQuery(),
    })
    .then(parseRes)
    .catch(parseErr);
}

export function getCsrfToken(): APIResponse<{ csrftoken: string }> {
  return webAPI.get('/misc/csrf-token/').then(parseRes).catch(parseErr);
}
