import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import styles from './SkeletonLoader.module.scss';

type Props = {
  variant?: 'lineOfText' | 'avatar';
  className?: string;
  style?: CSSProperties;
};

const defaultProps: Partial<Props> = {
  variant: 'lineOfText',
};

// renderer must specify width, height, or font size via className or style
const SkeletonLoader = (props: Props) => (
  <span
    style={props.style}
    className={classNames(props.className, {
      [styles.lineOfText]: props.variant === 'lineOfText',
      [styles.avatar]: props.variant === 'avatar',
      [styles.defaultSize]: !props.className, // this allows styles in className to override default sizing
    })}
  />
);

SkeletonLoader.defaultProps = defaultProps;

export default SkeletonLoader;
