import queryString from 'query-string';
import { getPageName } from '../routes';
import { User } from '../types';
import { isServer } from '../util';
import { isSegmentEnabled } from './featureFlagService';

// Segment recommends not calling identify() for anon visitors https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
export function identifyUser(user: User) {
  // Other traits are filled in by the server
  window.analytics.identify(user.id.toString(), {}, { Intercom: { user_hash: user.intercom_user_hash } });
}

export function getSegmentAnonymousId(): string | undefined {
  if (typeof window.analytics.user === 'function' && typeof window.analytics.user().anonymousId === 'function') {
    return window.analytics.user().anonymousId();
  } else {
    return undefined;
  }
}

// Sends event to Google Tag Manager, which also sends events to mixpanel under the right circumstances
export function trackEvent(data: {
  event: 'analytics_event' | 'cookieConsentEvent';
  event_name?: string;
  pretty_name?: string;
  event_common_attributes?: Record<string, any>;
}) {
  if (!isServer()) {
    window.dataLayer?.push(data);
  }
}

export function segmentTrackEvent(eventName: string, properties?: Record<string, any>) {
  if (!isServer()) {
    window.analytics.track(eventName, properties);
  }
}

// Sends page event to Segment, which is needed to load 3rd party snippets as well
export function trackPageViewEvent(data: { hash: string; pathname: string; search: string }) {
  const pageName = getPageName(data.pathname);

  // extract utm params
  const queryStrParams = queryString.parse(data.search, { arrayFormat: 'comma' });
  const utmParams = Object.keys(queryStrParams)
    .filter((param) => param.startsWith('utm_'))
    .reduce<Record<string, string>>((acc, param) => {
      const val = queryStrParams[param];

      if (val && typeof val === 'string') {
        acc[param] = val;
      }

      return acc;
    }, {});

  if (isSegmentEnabled()) {
    window.analytics.page({
      name: pageName,
      hash: data.hash,
      queryStrParams,
      pageType: 'marketing',
      title: undefined, // Segment automatically sends page title, but it's inaccurate due to how we set page titles (react-helmet)
      ...utmParams,
    });
  } else {
    trackEvent({
      event: 'analytics_event',
      event_name: 'visited_marketing_page',
      pretty_name: 'Visited Marketing Website Page',
      event_common_attributes: {
        page: pageName,
        pathname: data.pathname,
        hash: data.hash,
        queryStrParams,
        ...utmParams,
      },
    });
  }
}
