import React from 'react';

import styles from './AppStoreLink.module.scss';
import GooglePlayBadge from '../media/google-play-badge.svg';
import AppleAppStoreBadge from '../media/apple-app-store-badge.svg';
import AppleAppStoreBadgeLight from '../media/apple-app-store-badge-light.svg';
import Link from './Link';
import { segmentTrackEvent } from '../services/analyticsService';
import { isSegmentEnabled } from '../services/featureFlagService';

type AppleIconProps = {
  variant: 'apple';
  color: 'dark' | 'light';
};

type GoogleIconProps = {
  variant: 'google';
  // current play store guidelines only allow for a dark https://play.google.com/intl/en_us/badges/
  color: 'dark';
};

type Props = (AppleIconProps | GoogleIconProps) & {
  campaignId?: 'GooseChase-Footer';
  className?: string;
};

const defaultProps: Partial<Props> = {
  color: 'dark',
};

const AppStoreLink = (props: Props) => {
  let url: string | undefined;
  if (props.variant === 'google') {
    url = `https://play.google.com/store/apps/details?id=com.goosechaseadventures.goosechase${
      props.campaignId ? `&pcampaignid=${props.campaignId}` : ''
    }`;
  } else if (props.variant === 'apple') {
    url = 'https://itunes.apple.com/us/app/goosechase/id437330879?mt=8';
  }

  const ariaLabel =
    props.variant === 'google' ? 'Goosechase app on Google Play Store' : 'Goosechase app on Apple App Store';

  const onClick = () => {
    if (isSegmentEnabled()) {
      segmentTrackEvent(props.variant === 'google' ? 'Play Store Link Clicked' : 'App Store Link Clicked', {
        category: 'Exit',
        action: 'Click',
        label: props.variant === 'google' ? 'Google Play Store Listing' : 'Apple App Store Listing',
      });
    }
  };

  return (
    <Link
      href={url}
      className={props.className}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {props.variant === 'google' && <GooglePlayBadge className={styles.badgeImg} />}
      {props.variant === 'apple' && props.color === 'dark' && <AppleAppStoreBadge className={styles.badgeImg} />}
      {props.variant === 'apple' && props.color === 'light' && <AppleAppStoreBadgeLight className={styles.badgeImg} />}
    </Link>
  );
};

AppStoreLink.defaultProps = defaultProps;

export default AppStoreLink;
