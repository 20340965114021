import React, { useState } from 'react';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';

import GoosechaseLogo from '../../media/goosechase-logo.svg';
import Facebook from '../../media/facebook.svg';
import Twitter from '../../media/twitter.svg';
import Instagram from '../../media/instagram.svg';
import YouTube from '../../media/youtube.svg';

import utilStyles from '../../styles/util.module.scss';
import styles from './Footer.module.scss';
import Container from '../layout/Container';
import Link from '../Link';
import routes, { EXPERIENCE_LIBRARY_ROUTE } from '../../routes';
import Grid from '../layout/Grid';
import { isServer } from '../../util';
import AppStoreLink from '../AppStoreLink';
import Flex from '../layout/Flex';
import Icon from '../icons/Icon';
import NewsletterForm from '../NewsletterForm';
import Box from '../layout/Box';
import appConstants from '../../appConstants';

const currYear = new Date().getFullYear();
const footerLinkProps: Partial<React.ComponentProps<typeof Link>> = {
  className: styles.pageLink,
  variant: 'light',
};

const onContactClick = (e: React.MouseEvent) => {
  if (!isServer() && window.Intercom) {
    e.preventDefault();
    window.Intercom('show');
  }
};

const Footer = () => {
  const [gooseChaseExpanded, setGooseChaseExpanded] = useState(false);
  const [resourcesExpanded, setResourcesExpanded] = useState(false);

  const theme = useTheme();

  // js media query is used to not render the same links in the DOM twice (for SEO) - as is needed due to the complexity of
  // the different layouts for mobile vs desktop
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function toggleGooseChaseExpanded() {
    setGooseChaseExpanded(!gooseChaseExpanded);
  }

  function toggleResourcesExpanded() {
    setResourcesExpanded(!resourcesExpanded);
  }

  const copyrightText = (
    <div className={styles.copyrightText}>
      <div>Copyright © {currYear} Goosechase Adventures Inc.</div>
      <div>All rights reserved.</div>
    </div>
  );

  const socialMediaIcons = (
    <Flex alignItems="center" justifyContent={isMobile ? 'center' : undefined}>
      <Link
        className={styles.socialIcon}
        href="https://www.facebook.com/goosechaseadventures/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Goosechase Facebook page"
      >
        <Facebook height="2.4rem" />
      </Link>
      <Link
        className={styles.socialIcon}
        href="https://twitter.com/goosechase"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Goosechase Twitter"
      >
        <Twitter height="2.4rem" />
      </Link>
      <Link
        className={styles.socialIcon}
        href="https://www.instagram.com/goosechase/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Goosechase Instagram"
      >
        <Instagram height="2.4rem" />
      </Link>
      <Link
        className={styles.socialIcon}
        href="https://www.youtube.com/user/GooseChaseAdventures"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Goosechase YouTube channel"
      >
        <YouTube height="2.4rem" />
      </Link>
    </Flex>
  );

  const gooseChaseLinks = (
    <>
      <Link {...footerLinkProps} href={routes.ABOUT.path} useRouter>
        About us
      </Link>
      <Link {...footerLinkProps} href={appConstants.CAREERS_URL}>
        Careers 🚀
      </Link>
      <Link {...footerLinkProps} href={routes.CONTACT.path} useRouter onClick={onContactClick}>
        Contact us
      </Link>
      <Link {...footerLinkProps} href={routes.PRIVACY.path} useRouter>
        Privacy policy
      </Link>
      <Link {...footerLinkProps} href={routes.TERMS_OF_SERVICE.path} useRouter>
        Terms of service
      </Link>
      <Link {...footerLinkProps} href={routes.COOKIE_POLICY.path} useRouter>
        Cookie settings
      </Link>
    </>
  );

  const resourceLinks = (
    <>
      <Link {...footerLinkProps} href={routes.WHY_BUY_A_SUBSCRIPTION.path} useRouter>
        Pricing
      </Link>
      <Link {...footerLinkProps} href="https://blog.goosechase.com" target="_blank">
        Blog
      </Link>
      <Link {...footerLinkProps} href={EXPERIENCE_LIBRARY_ROUTE.path} target="_blank">
        Template Library
      </Link>
      <Link {...footerLinkProps} href="https://community.goosechase.com/" target="_blank">
        The Nest
      </Link>
      <Link
        {...footerLinkProps}
        href="https://support.goosechase.com/en/collections/2545171-frequently-asked-questions-faq"
        target="_blank"
      >
        FAQ
      </Link>
      <Link
        {...footerLinkProps}
        href="https://drive.google.com/drive/u/1/folders/104huOFkcz5t3J-iNyOsbKkRrZi1WkA_S"
        target="_blank"
      >
        Media kit
      </Link>
      <Link {...footerLinkProps} href={routes.DOWNLOAD.path} useRouter>
        Download app
      </Link>
      <Link {...footerLinkProps} href="https://status.goosechase.com">
        Status
      </Link>
    </>
  );

  return (
    <footer className={styles.footer}>
      <Container className={styles.container} maxWidth="md">
        <Grid container spacing={0}>
          {!isMobile && (
            <Grid item md="auto">
              <div className={styles.followUsSection}>
                <div className={styles.groupTitle}>Follow us</div>
                {socialMediaIcons}
                <Flex>
                  <AppStoreLink className={styles.appleAppBadge} variant="apple" color="dark" />
                </Flex>
                <Flex>
                  <AppStoreLink className={styles.googleAppBadge} variant="google" color="dark" />
                </Flex>
                {copyrightText}
              </div>
            </Grid>
          )}
          <Grid item md="auto" xs={12}>
            {isMobile ? (
              <div>
                <div
                  className={styles.groupTitleWrapper}
                  onClick={toggleGooseChaseExpanded}
                  role="button"
                  tabIndex={0}
                  aria-expanded={gooseChaseExpanded}
                >
                  <div className={styles.groupTitle}>Goosechase</div>
                  <Icon
                    className={classNames(styles.arrow, {
                      [styles.arrowExpanded]: gooseChaseExpanded,
                    })}
                    name="arrowDown"
                    height="2.4rem"
                  />
                </div>
                <div
                  className={classNames(styles.slideContent, {
                    [styles.expanded]: gooseChaseExpanded,
                  })}
                >
                  {gooseChaseLinks}
                </div>
              </div>
            ) : (
              <div className={styles.linksSection}>
                <div className={styles.groupTitle}>Goosechase</div>
                {gooseChaseLinks}
              </div>
            )}
          </Grid>
          <Grid item md="auto" xs={12}>
            {isMobile ? (
              <div>
                <div
                  className={styles.groupTitleWrapper}
                  onClick={toggleResourcesExpanded}
                  role="button"
                  tabIndex={0}
                  aria-expanded={resourcesExpanded}
                >
                  <div className={styles.groupTitle}>Resources</div>
                  <Icon
                    className={classNames(styles.arrow, {
                      [styles.arrowExpanded]: resourcesExpanded,
                    })}
                    name="arrowDown"
                    height="2.4rem"
                  />
                </div>
                <div
                  className={classNames(styles.slideContent, {
                    [styles.expanded]: resourcesExpanded,
                  })}
                >
                  {resourceLinks}
                </div>
              </div>
            ) : (
              <div className={styles.resourcesSection}>
                <div className={styles.groupTitle}>Resources</div>
                {resourceLinks}
              </div>
            )}
          </Grid>
          <Grid item md="flex" xs={12}>
            <div className={styles.newsletterSection}>
              {isMobile && (
                <>
                  <div className={styles.mobileSocialIconsWrapper}>{socialMediaIcons}</div>
                  {/* These svg must not exist in DOM at same time as ones above, or else css rules will only apply to one of them */}
                  <Box display="flex" my="6.8rem">
                    <AppStoreLink className={styles.appleAppBadge} variant="apple" color="dark" />
                    <AppStoreLink className={styles.googleAppBadge} variant="google" color="dark" />
                  </Box>
                </>
              )}

              <GoosechaseLogo className={styles.logo} height="3.8rem" />
              <NewsletterForm />
              <div className={utilStyles.hiddenMdUp}>{copyrightText}</div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
