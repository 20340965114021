import React from 'react';

import BlogIcon from '../../media/richIcons/blog.svg';
import templatesIcon from '../../media/richIcons/templates.svg';
import TheNestIcon from '../../media/richIcons/theNest.svg';
import { EXPERIENCE_LIBRARY_ROUTE } from '../../routes';

import Flex from '../layout/Flex';
import navStyles from './Navbar.module.scss';
import NavDropdown from './NavDropdown';
import { NavMenuItem } from './NavMenuItem';
import Icon from '../icons/Icon';

type Props = {
  isDesktop: boolean;
};

export const ResourcesNav = ({ isDesktop }: Props) => {
  if (!isDesktop) {
    return (
      <>
        <div className={navStyles.mobileGroupTitle}>Resources</div>
        <ResourcesMenuItems />
      </>
    );
  }

  return (
    <NavDropdown className={navStyles.linkItem}>
      <NavDropdown.Toggle>
        Resources <Icon name="arrowDown" height="2.4rem" css={{ verticalAlign: 'bottom' }} />
      </NavDropdown.Toggle>
      <NavDropdown.Menu>
        <Flex>
          <div className={navStyles.navUsesColumn}>
            <ResourcesMenuItems />
          </div>
        </Flex>
      </NavDropdown.Menu>
    </NavDropdown>
  );
};

const ResourcesMenuItems = () => (
  <>
    <NavMenuItem
      title="The Nest"
      desc="Join our creator community to connect, ask questions, and share ideas."
      href="https://community.goosechase.com/"
      icon={TheNestIcon}
      useRouter={false}
      target="_blank"
    />
    <NavMenuItem
      title="Blog"
      desc="News, stories, and inspiration to help you run exceptional Experiences."
      href="https://blog.goosechase.com/"
      icon={BlogIcon}
      useRouter={false}
      target="_blank"
    />
    <NavMenuItem
      title="Template Library"
      desc="Browse 250+ Experience Templates that are ready to grab, customize, and run."
      href={EXPERIENCE_LIBRARY_ROUTE.path}
      icon={templatesIcon}
    />
  </>
);
